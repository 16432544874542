import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
var render = function render() {
  var _vm$orderInfo;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "detail-box"
  }, [_c("div", {
    staticClass: "order-detail"
  }, [_c("img", {
    staticClass: "logoImg",
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/src/assets/logo.jpg",
      alt: "logo"
    }
  }), _c("div", {
    staticClass: "title"
  }, [_vm._v("游全球")]), _c("div", {
    staticClass: "price"
  }, [_vm._v("-" + _vm._s((_vm$orderInfo = _vm.orderInfo) === null || _vm$orderInfo === void 0 ? void 0 : _vm$orderInfo.payPrice))])]), _c("div", {
    staticClass: "order-info"
  }, [Object.keys(_vm.orderInfo).length > 0 ? _c("div", {
    ref: "content",
    staticClass: "order-table"
  }, [_c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("交易编号 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(" " + _vm._s(_vm.orderInfo.outTradeNo) + " ")])]), _c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("充值日期 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(" " + _vm._s(_vm.orderInfo.created) + " ")])]), _c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("充值号码 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(_vm._s(_vm.orderInfo.account))])]), _c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("充值产品 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(_vm._s(_vm.orderInfo.body))])]), _c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("运营商 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(_vm._s(_vm.orderInfo.carrier))])]), _c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("交易金额 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(_vm._s(_vm.orderInfo.payPrice) + " 元")])]), _vm._m(0), _c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("充值状态 :")]), _c("div", {
    staticClass: "right",
    style: {
      color: _vm.orderStatus(_vm.orderInfo.status).color
    }
  }, [_vm._v(" " + _vm._s(_vm.orderStatus(_vm.orderInfo.status).text) + " "), _vm.orderInfo.pinCode != null ? _c("span", {
    staticClass: "PinButton",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.getPinCode(_vm.orderInfo);
      }
    }
  }, [_vm._v("立即兑换")]) : _vm._e()])]), _vm.orderInfo.complain && _vm.complainInfo.status ? _c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("投诉详情:")]), _c("div", {
    staticClass: "right",
    style: {
      color: _vm.complainStatus(_vm.complainInfo.status).color
    }
  }, [_c("span", {
    staticStyle: {
      "margin-right": "10px"
    }
  }, [_vm._v(_vm._s(_vm.complainStatus(_vm.complainInfo.status).text))]), _vm.complainInfo.status == "FINISHED" ? _c("button", {
    staticStyle: {
      color: "#000",
      "margin-left": "20px"
    },
    on: {
      click: function click($event) {
        _vm.showComplainDetailVisiable = true;
      }
    }
  }, [_vm._v(" 查看详情 ")]) : _vm._e()])]) : _vm._e(), _vm.orderInfo.complain && _vm.complainInfo.status && (_vm.complainInfo.status === "NEW" || _vm.complainInfo.status === "PROCESSING") ? _c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("投诉处理倒计时 :")]), _c("div", {
    staticClass: "right",
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(" " + _vm._s(_vm.deadLine) + " ")])]) : _vm._e(), _c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("使用优惠 :")]), _c("div", {
    staticClass: "right"
  }, [_vm.orderInfo.selected || _vm.orderInfo.firstDiscount ? _c("span", [_vm._v("使用" + _vm._s(_vm.orderInfo.selected === "point" && "积分" || _vm.orderInfo.selected === "coupon" && "优惠券" || _vm.orderInfo.firstDiscount && "首单优惠") + " "), _c("b", [_vm._v(" -￥" + _vm._s(_vm._f("toFixed2")(_vm.orderInfo.originalPrice - _vm.orderInfo.payPrice)))])]) : _c("span", [_vm._v("暂无")])])]), _vm.isMyTaxValueArrItem() && _vm.orderInfo.type === "HF" ? _c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("备注 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v(" 每笔话费充值，" + _vm._s(_vm.countryInfo.cn) + "政府均收取" + _vm._s(_vm.currentTipsInfo.taxValue) + "%税款；实际到账以到账短信为准！此不作为退款理由。 ")])]) : _vm._e()]) : _vm._e()]), _vm.orderInfo.status === "new_order" ? _c("em", {
    staticClass: "pay",
    on: {
      click: function click($event) {
        return _vm.payNow(_vm.orderInfo);
      }
    }
  }, [_vm._v("立即支付")]) : _vm._e(), _vm.subscribeOffice ? _c("img", {
    staticClass: "gzImg",
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/image/guanzhu3.png",
      alt: "关注"
    },
    on: {
      click: _vm.toGongZhongHao
    }
  }) : _vm._e(), !_vm.subscribeOffice ? _c("img", {
    attrs: {
      width: "100%",
      src: require("@@/userCenter/vipArtBanner.png"),
      alt: "积分"
    },
    on: {
      click: _vm.popUserPointImage
    }
  }) : _vm._e(), _c("div", {
    staticStyle: {
      height: "8px",
      "background-color": "#efefef",
      "margin-top": "20px"
    }
  }), _c("div", {
    staticClass: "serve"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("账单服务")]), _c("div", {
    staticClass: "serve-type"
  }, [_vm.orderInfo.status !== "fail" && _vm.orderInfo.status !== "close" ? _c("div", {
    staticClass: "serve-item",
    on: {
      click: _vm.toComplaint
    }
  }, [_c("i", {
    staticClass: "iconfont icon-tishi1",
    staticStyle: {
      "font-size": "18px",
      "vertical-align": "-2px",
      "margin-right": "4px"
    }
  }), _vm._v("投诉订单 ")]) : _vm._e(), _c("div", {
    staticClass: "serve-item",
    on: {
      click: _vm.linkKeFu
    }
  }, [_c("i", {
    staticClass: "iconfont icon-kefu1",
    staticStyle: {
      "font-size": "20px",
      "vertical-align": "-2px",
      "margin-right": "4px"
    }
  }), _vm._v("联系客服 ")]), _c("div", {
    staticClass: "serve-item",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.getQueryInfo(_vm.orderInfo);
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-zhangdan",
    staticStyle: {
      "font-size": "20px",
      "vertical-align": "-2px",
      "margin-right": "4px"
    }
  }), _vm._v("查询余额 ")]), _vm.orderInfo.pinCode != null ? _c("div", {
    staticClass: "serve-item",
    on: {
      click: function click($event) {
        return _vm.getPinCode(_vm.orderInfo);
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-kefu1",
    staticStyle: {
      "font-size": "20px",
      "vertical-align": "-2px",
      "margin-right": "4px"
    }
  }), _vm._v("卡密信息 ")]) : _vm._e(), _vm.orderInfo.status === "success" ? _c("div", {
    staticClass: "serve-item",
    on: {
      click: function click($event) {
        return _vm.print("zh");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-xiaoxizhangdan",
    staticStyle: {
      "font-size": "20px",
      "vertical-align": "-2px",
      "margin-right": "4px"
    }
  }), _vm._v("打印交易凭证 ")]) : _vm._e()])]), _c("van-popup", {
    staticClass: "fansGroupPopup",
    model: {
      value: _vm.fansGroupVisible,
      callback: function callback($$v) {
        _vm.fansGroupVisible = $$v;
      },
      expression: "fansGroupVisible"
    }
  }, [_c("img", {
    staticStyle: {
      "border-radius": "10px",
      width: "350px"
    },
    attrs: {
      width: "100%",
      src: _vm.imgUrl,
      alt: "扫码加群"
    }
  })]), _c("van-dialog", {
    attrs: {
      width: "70%",
      title: "卡密信息"
    },
    on: {
      confirm: function confirm($event) {}
    },
    model: {
      value: _vm.showDG,
      callback: function callback($$v) {
        _vm.showDG = $$v;
      },
      expression: "showDG"
    }
  }, [_c("van-cell-group", [_c("van-cell", {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("span", {
          staticClass: "custom-title",
          staticStyle: {
            "font-size": "15px",
            "font-weight": "bold"
          }
        }, [_vm._v("PIN码:")]), _c("p", {
          staticClass: "pinCode",
          attrs: {
            id: "pinCode"
          }
        }, [_vm._v(_vm._s(_vm.pinObj.pinCode))]), _c("div", {
          staticStyle: {
            padding: "3px 20px",
            height: "30px",
            "line-height": "30px",
            "margin-top": "2px",
            "text-align": "center"
          }
        }, [_c("button", {
          staticClass: "copyButton",
          on: {
            click: _vm.copyPin
          }
        }, [_vm._v("复制卡密")])])];
      },
      proxy: true
    }])
  }), _c("van-cell", {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        var _vm$orderInfo2;
        return [_c("span", {
          staticClass: "custom-title",
          staticStyle: {
            "font-size": "15px",
            "font-weight": "bold"
          }
        }, [_vm._v("充值操作:")]), _c("div", {
          staticClass: "rechargeMethod"
        }, [_vm._v(" " + _vm._s(_vm.pinObj.pinQueryUrl) + " "), _vm.isMyTaxValueArrItem() && ((_vm$orderInfo2 = _vm.orderInfo) === null || _vm$orderInfo2 === void 0 ? void 0 : _vm$orderInfo2.type) === "HF" ? _c("span", [_vm._v(" " + _vm._s(_vm.countryInfo.cn) + "为后扣税国家，政府收取" + _vm._s(_vm.currentTipsInfo.taxValue) + "%的消费税，到账面值" + _vm._s((1 - _vm.currentTipsInfo.taxValue / 100) * 100) + "%的金额，实际到账以运营商到账短信为准，此不作为退款理由。 ")]) : _vm._e()])];
      },
      proxy: true
    }])
  })], 1), _vm.pinObj.pinStudyNewsId ? _c("div", {
    staticClass: "skipBtnDetail",
    on: {
      click: _vm.skipToImageTextDetailPage
    }
  }, [_c("img", {
    staticClass: "skipBtnDetailIcon",
    attrs: {
      src: require("@/assets/home/exclaimOver.png"),
      width: "32px"
    }
  }), _c("span", {
    staticStyle: {
      "margin-top": "3px",
      "font-size": "16px",
      "margin-left": "8px"
    }
  }, [_vm._v("卡密兑换教程")])]) : _vm._e(), _vm.pinObj.smsUssdPhone ? _c("div", {
    staticClass: "box-content",
    on: {
      click: _vm.sendMessage
    }
  }, [_c("div", {
    staticClass: "box"
  }, [_c("i", {
    staticClass: "iconfont icon-xiaoxi",
    staticStyle: {
      "font-size": "24px",
      "vertical-align": "-14px"
    }
  })]), _c("div", {
    staticStyle: {
      "margin-top": "3px",
      "font-size": "16px",
      "margin-left": "8px"
    }
  }, [_vm._v("短信兑换")])]) : _vm._e(), _vm.pinObj.smsUssdPhone == "" || _vm.pinObj.smsUssdPhone == null && _vm.pinObj.phoneUssdCode ? _c("div", {
    staticClass: "box-content",
    on: {
      click: function click($event) {
        return _vm.makePhoneCall(_vm.pinObj.phoneUssdCode);
      }
    }
  }, [_c("div", {
    staticClass: "box"
  }, [_c("i", {
    staticClass: "iconfont icon-24gf-telephone",
    staticStyle: {
      "font-size": "20px",
      "vertical-align": "-12px"
    }
  })]), _c("div", {
    staticStyle: {
      "margin-top": "3px",
      "font-size": "16px",
      "margin-left": "8px"
    }
  }, [_vm._v("拨号兑换")])]) : _vm._e()], 1), _c("van-dialog", {
    attrs: {
      confirmButtonText: "满意",
      cancelButtonText: "不满意",
      "close-on-click-overlay": "false",
      "show-cancel-button": "",
      title: "客服处理情况",
      "before-close": _vm.beforeClose
    },
    model: {
      value: _vm.showComplainDetailVisiable,
      callback: function callback($$v) {
        _vm.showComplainDetailVisiable = $$v;
      },
      expression: "showComplainDetailVisiable"
    }
  }, [Object.keys(_vm.complainInfo).length > 0 ? _c("div", {
    staticStyle: {
      "text-align": "center",
      padding: "20px"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#939393"
    }
  }, [_vm._v("投诉问题：")]), _vm._v(" " + _vm._s(_vm.complainInfo.reason) + " ")]), _c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#939393"
    }
  }, [_vm._v("投诉状态：")]), _vm._v(" " + _vm._s(_vm.complainStatus(_vm.complainInfo.status).text) + " ")]), _c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#939393"
    }
  }, [_vm._v("客服回复：")]), _vm._v(" " + _vm._s(_vm.complainInfo.serverReply) + " ")]), _c("div", {
    staticStyle: {
      margin: "10px auto",
      "text-align": "center",
      padding: "10px 20px",
      "font-size": "16px",
      color: "white",
      "background-color": "#007bff",
      "border-radius": "5px",
      cursor: "pointer"
    },
    on: {
      click: _vm.popCustomerService
    }
  }, [_vm._v(" 联系专属客服 ")])]) : _vm._e()]), _c("van-dialog", {
    attrs: {
      "close-on-click-overlay": "",
      title: "投诉详情"
    },
    model: {
      value: _vm.showComplainDetailVisiable2,
      callback: function callback($$v) {
        _vm.showComplainDetailVisiable2 = $$v;
      },
      expression: "showComplainDetailVisiable2"
    }
  }, [Object.keys(_vm.complainInfo).length > 0 ? _c("div", {
    staticStyle: {
      "text-align": "left",
      padding: "20px"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#939393"
    }
  }, [_vm._v("投诉问题：")]), _vm._v(" " + _vm._s(_vm.complainInfo.reason) + " ")]), _c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#939393"
    }
  }, [_vm._v("投诉状态：")]), _vm._v(" " + _vm._s(_vm.complainStatus(_vm.complainInfo.status).text) + " ")]), _c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#939393"
    }
  }, [_vm._v("客服回复：")]), _vm._v(" " + _vm._s(_vm.complainInfo.serverReply) + " ")]), _c("div", {
    staticStyle: {
      margin: "10px auto",
      "text-align": "center",
      padding: "10px 20px",
      "font-size": "16px",
      color: "white",
      "background-color": "#007bff",
      "border-radius": "5px",
      cursor: "pointer"
    },
    on: {
      click: _vm.popCustomerService
    }
  }, [_vm._v(" 联系专属客服 ")])]) : _vm._e()]), _c("van-popup", {
    staticStyle: {
      "text-align": "center",
      "background-color": "transparent"
    },
    model: {
      value: _vm.isSubscribeOfficeVisiable,
      callback: function callback($$v) {
        _vm.isSubscribeOfficeVisiable = $$v;
      },
      expression: "isSubscribeOfficeVisiable"
    }
  }, [_c("img", {
    staticStyle: {
      width: "300px",
      height: "430px",
      "border-radius": "10px"
    },
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/image/officeGuideSubscribe.jpg"
    }
  })]), _c("van-dialog", {
    attrs: {
      title: "",
      "confirm-button-text": "↑↑↑长按上图保存到相册",
      width: "360",
      "show-confirm-button": false,
      "close-on-click-overlay": ""
    },
    model: {
      value: _vm.showVisiable,
      callback: function callback($$v) {
        _vm.showVisiable = $$v;
      },
      expression: "showVisiable"
    }
  }, [_c("TopupCertificate", {
    attrs: {
      orderInfo: _vm.orderInfo
    }
  })], 1), _c("van-dialog", {
    attrs: {
      "close-on-click-overlay": "",
      title: "查询余额"
    },
    model: {
      value: _vm.queryDialogVisible,
      callback: function callback($$v) {
        _vm.queryDialogVisible = $$v;
      },
      expression: "queryDialogVisible"
    }
  }, [_vm.carrierQueryMethodInfo && _vm.carrierQueryMethodInfo.carrierInfoList ? _c("div", {
    staticStyle: {
      "text-align": "left",
      padding: "20px"
    }
  }, [_vm.carrierQueryMethodInfo.carrierInfoList.length > 0 ? _c("div", _vm._l(_vm.carrierQueryMethodInfo.carrierInfoList, function (item, index) {
    return _c("div", {
      key: item.id
    }, [item.valid ? _c("div", {
      staticClass: "item-card"
    }, [_c("div", {
      staticClass: "left"
    }, [_c("div", {
      staticClass: "title"
    }, [_vm._v(" " + _vm._s(item.type === "DATA" ? "流量余额查询" : item.type === "MOBILE" ? "话费余额查询" : item.type === "PIN" ? "充值卡查询" : item.type === "PHONE_NUMBER" ? "本机号码查询" : "暂无类型") + " ")]), _c("div", {
      staticClass: "desc"
    }, [_vm._v(_vm._s(item.remark ? item.remark : "暂无描述"))])]), _c("div", {
      staticClass: "right"
    }, [item.smsUssdCode ? _c("div", {
      staticClass: "box-content"
    }, [_vm.isios ? _c("a", {
      staticClass: "box",
      attrs: {
        href: "sms:".concat(item.smsUssdPhone, "&body=").concat(item.smsUssdCode)
      }
    }, [_c("i", {
      staticClass: "iconfont icon-xiaoxi",
      staticStyle: {
        "font-size": "24px",
        "vertical-align": "-14px"
      }
    })]) : _c("a", {
      staticClass: "box",
      attrs: {
        href: "sms:".concat(item.smsUssdPhone, "?body=").concat(item.smsUssdCode)
      }
    }, [_c("i", {
      staticClass: "iconfont icon-xiaoxi",
      staticStyle: {
        "font-size": "24px",
        "vertical-align": "-14px"
      }
    })]), _c("div", {
      staticStyle: {
        "margin-top": "3px"
      }
    }, [_vm._v("短信")])]) : _vm._e(), item.smsUssdCode == "" || item.smsUssdCode == null ? _c("div", {
      staticClass: "box-content",
      on: {
        click: function click($event) {
          return _vm.makePhoneCall(item.phoneUssdCode);
        }
      }
    }, [_c("div", {
      staticClass: "box"
    }, [_c("i", {
      staticClass: "iconfont icon-24gf-telephone",
      staticStyle: {
        "font-size": "20px",
        "vertical-align": "-12px"
      }
    })]), _c("div", {
      staticStyle: {
        "margin-top": "3px"
      }
    }, [_vm._v("拨号")])]) : _vm._e()])]) : _vm._e()]);
  }), 0) : _vm._e()]) : _vm._e()]), _c("van-dialog", {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      title: "长按扫码联系客服"
    },
    model: {
      value: _vm.showComponyWXImg,
      callback: function callback($$v) {
        _vm.showComponyWXImg = $$v;
      },
      expression: "showComponyWXImg"
    }
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/companyWx.png"),
      width: "90%"
    }
  })]), _c("van-dialog", {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      title: "长按扫码联系专属客服"
    },
    model: {
      value: _vm.showPopCustomerService,
      callback: function callback($$v) {
        _vm.showPopCustomerService = $$v;
      },
      expression: "showPopCustomerService"
    }
  }, [_c("img", {
    attrs: {
      src: this.handlerUserCpCode,
      width: "90%"
    }
  })])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "left"
  }, [_vm._v("货币类型 :")]), _c("div", {
    staticClass: "right"
  }, [_vm._v("人民币")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };