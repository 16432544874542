var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "shareFriends"
    }
  }, [_c("div", {
    staticClass: "image-container"
  }, [_c("img", {
    attrs: {
      src: require("@@/userCenter/shareHeadertitle.png"),
      alt: "邀好友 得积分"
    }
  }), _c("div", {
    staticStyle: {
      float: "right",
      "z-index": "9",
      position: "fixed",
      top: "100px",
      right: "0",
      background: "linear-gradient(to bottom right, #ff9a03, #fbd977)",
      width: "45px",
      "border-radius": "25px 0 0 25px",
      "text-align": "center",
      "font-size": "14px",
      padding: "5px 2px",
      color: "#f5f5f5"
    },
    on: {
      click: function click($event) {
        _vm.ruleVisible = true;
      }
    }
  }, [_vm._v(" 规则 ")])]), _c("div", {
    staticClass: "contentWrapper"
  }, [_c("div", {
    staticClass: "shareTipsWrapper"
  }, [_c("div", {
    staticClass: "shareTips"
  }, [_vm._m(0), _vm._m(1), _c("div", {
    staticStyle: {
      "margin-top": "15px",
      display: "flex",
      "justify-content": "center",
      "font-weight": "900"
    }
  }, [_vm.static && _vm.static.recommendCount % 10 != 0 ? _c("span", {
    staticStyle: {
      color: "#1a3efa",
      "font-size": "15px"
    }
  }, [_vm._v("距离奖励仅差" + _vm._s(10 - _vm.static.recommendCount % 10) + "位好友")]) : _c("span", {
    staticStyle: {
      color: "#ff2c00",
      "font-size": "18px"
    }
  }, [_vm._v(_vm._s(_vm.static && _vm.static.recommendCount == 0 ? "" : "恭喜您成功领取奖励！") + " ")])]), _vm.schedule > 0 ? _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c("div", {
    staticStyle: {
      width: "70%",
      height: "15px",
      "background-color": "#e0e0e0",
      "border-radius": "5px",
      position: "relative",
      top: "15px",
      left: "10px"
    },
    attrs: {
      id: "progressBarContainer"
    }
  }, [_c("div", {
    staticStyle: {
      width: "30%",
      height: "100%",
      "background-color": "#0062eb",
      "border-radius": "15px",
      position: "relative",
      display: "flex",
      "align-items": "center",
      "justify-content": "center",
      color: "white",
      "font-weight": "bold"
    },
    style: {
      width: _vm.schedule + "%",
      border: _vm.static && _vm.static.recommendCount > 0 && _vm.static.recommendCount % 10 == 0 ? "2px solid  #fa5502" : _vm.none
    },
    attrs: {
      id: "progressBar"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "10px",
      color: "while"
    },
    style: {
      marginLeft: _vm.schedule > 50 ? _vm.schedule - 15 + "%" : _vm.schedule + "%"
    }
  }, [_vm._v(_vm._s(_vm.schedule != 100 ? _vm.static.recommendCount + "/" + _vm.ceilToNearestTenNum + "" : "") + " ")])])]), _c("img", {
    attrs: {
      id: "progressIcon",
      src: require("@@/userCenter/100points.png"),
      alt: "",
      width: "70px"
    }
  })]) : _vm._e(), _vm._m(2), _c("div", {
    staticClass: "invite"
  }, [_c("div", {
    ref: "targetElement",
    staticClass: "invite-left",
    on: {
      click: _vm.inviteFriends
    }
  }, [_vm._v(" 立即邀请 ")]), _c("wx-open-subscribe", {
    staticStyle: {
      width: "200px",
      height: "55px",
      position: "absolute",
      opacity: "0"
    },
    attrs: {
      template: _vm.subTemplateId,
      id: "subscribe-btn"
    },
    on: {
      success: _vm.success,
      error: _vm.subError
    }
  }, [_c("script", {
    attrs: {
      type: "text/wxtag-template"
    }
  }, [_vm._v(' <style> .btn2 { width: 200px; height: 85px; border: none; padding: 15px 0; color: #fff; margin: 0 auto; } </style> <button class="btn2">立即参与</button> ')])])], 1)]), _c("div", {
    staticStyle: {
      width: "90%",
      display: "flex",
      padding: "4px",
      margin: "30px auto",
      "border-radius": "10px",
      "background-color": "#0060bb",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "margin-left": "30px"
    }
  }, [_c("img", {
    attrs: {
      src: require("@@/userCenter/CircleOfFriends.png"),
      width: "25",
      alt: ""
    }
  }), _c("span", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "900",
      color: "#fff",
      "margin-left": "8px"
    },
    on: {
      click: _vm.inviteFriends
    }
  }, [_vm._v("朋友圈邀请")]), _c("wx-open-subscribe", {
    staticStyle: {
      width: "40vw",
      height: "20px",
      position: "absolute",
      opacity: "0"
    },
    attrs: {
      template: _vm.subTemplateId,
      id: "subscribe-btn"
    },
    on: {
      success: _vm.success,
      error: _vm.subError
    }
  }, [_c("script", {
    attrs: {
      type: "text/wxtag-template"
    }
  }, [_vm._v(' <style> .btn2 { width: 120px; height: 50px; border: none; padding: 15px 0; color: #fff; } </style> <button class="btn2">立即参与</button> ')])])], 1), _c("span", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "900",
      color: "#fff"
    }
  }, [_vm._v("|")]), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "text-align": "right",
      "margin-right": "30px"
    }
  }, [_c("img", {
    attrs: {
      src: require("@@/userCenter/qrcodeInvite.png"),
      width: "25",
      alt: ""
    }
  }), _c("span", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "900",
      color: "#fff",
      "margin-left": "8px"
    },
    on: {
      click: _vm.createdImg
    }
  }, [_vm._v("面对面扫码")]), _c("wx-open-subscribe", {
    staticStyle: {
      width: "40vw",
      height: "20px",
      position: "absolute",
      opacity: "0"
    },
    attrs: {
      template: _vm.subTemplateId,
      id: "subscribe-btn"
    },
    on: {
      success: _vm.success2,
      error: _vm.subError2
    }
  }, [_c("script", {
    attrs: {
      type: "text/wxtag-template"
    }
  }, [_vm._v(' <style> .btn2 { width: 120px; height: 50px; border: none; padding: 15px 0; color: #fff; } </style> <button class="btn2">立即参与</button> ')])])], 1)]), _vm._m(3), _c("div", {
    staticClass: "shareTips shareTips2"
  }, [_c("div", {
    staticClass: "shareTips2-title"
  }, [_vm._v("我的成就")]), _c("div", {
    staticClass: "shareTips2-body"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("已成功邀请")]), _c("div", {
    staticClass: "num"
  }, [_c("span", {
    staticClass: "num1"
  }, [_vm._v(_vm._s(_vm.static && _vm.static.recommendCount || 0))]), _c("span", {
    staticClass: "num2"
  }, [_vm._v("人")])])]), _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("已获得奖励")]), _c("div", {
    staticClass: "num"
  }, [_c("span", {
    staticClass: "num1"
  }, [_vm._v(_vm._s(_vm.static && _vm.static.totalPoints || 0))]), _c("span", {
    staticClass: "num2"
  }, [_vm._v("积分")])])])]), _c("span", {
    staticClass: "toShop",
    on: {
      click: _vm.toIndex
    }
  }, [_vm._v("去充值")]), _c("div", {
    staticClass: "shareTips2-record"
  }, [_c("div", {
    on: {
      click: _vm.toShareRecord
    }
  }, [_vm._v("查看我的邀请记录")])])])]), _vm.showMask ? _c("div", {
    staticClass: "mask"
  }, [_c("img", {
    staticClass: "arrow",
    attrs: {
      src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAACICAMAAABQgAwUAAAAMFBMVEUAAADY2NjY2NjY2NjY2NjY2NjY2NjY2NjX19fY2NjY2NjY2NjY2NjY2NjY2NjY2Njr/TvvAAAAD3RSTlMAxy89c9CdTRyG7lvcD7FzqbJAAAACFklEQVR42uWYy4rkMBAErZdlPdzx/3+7LAw0tH0Y2orDsnnyKQlSVaWytoc6xrEpigFoinUAIBnWABAE5woW9o6GPbGwI1jYGSzsgoV9goU9wMLe0bA7FnYCC7uBhV2wsE+wsAdY2AENGyzsBBZ2Q8MuWNgH94pLbgELO6Bhg4VdwcJuaNgTCzuChZ3Bwg5o2GBhV7CwdzTsjoUdwcLOYGEXLOwTLOwBFvaOht2xsBNY2I1f6lhaenvhrfpkAblab+k9b/OD0iuX2F9/x8D+7ZL2pmpbuj+6o3Vg//oWmPU9p65VkXL6+oIJ8S738nwj62Pb1lvHACH+fBs7sG59U3yrVD3rce3GVcp8qGkPAGTprQUYy6xfaE8i82b6S7/pfZnzdYQIHeOXdfYKpHoFcmrvWlM8RW+CDO8JMWoNM/+FeyB4UfMpL48g5qG1Iqc29YI3mqq2knXvEJu2onJoQy9ok4mkQZf/GjqitUvQyqN6SU8NOvOhHq25xNCWj6LFQdLiyKuaZWpxBC2OrFVHxdryElbQsVtBx6KN0qAd4a71yo610uxa2b0s5xg052I5p26d4MCqusZFwzrAnqQhSogSMnkNcr+GUS3kEKWS62NJFlNCToWLZpWMe14RReGqdjz2PfNECbkGbrQ/Nj5q5y7j8/HRTW5UhvHfA7Mdzitji8rfWsgX3gVZ91eO22odKed6LLf9A/sRnc74RV7lAAAAAElFTkSuQmCC",
      alt: ""
    }
  }), _vm._m(4), _c("div", {
    staticClass: "know",
    on: {
      click: _vm.closeMask
    }
  }, [_vm._v("我知道了")])]) : _vm._e()]), _c("van-dialog", {
    attrs: {
      title: "",
      "confirm-button-text": "↑↑↑长按上图保存到相册",
      "show-confirm-button": false,
      "close-on-click-overlay": ""
    },
    model: {
      value: _vm.showQrCodeVisiable,
      callback: function callback($$v) {
        _vm.showQrCodeVisiable = $$v;
      },
      expression: "showQrCodeVisiable"
    }
  }, [_vm.testUrl == "" ? _c("div", {
    staticStyle: {
      position: "relative"
    },
    attrs: {
      id: "container"
    }
  }, [_c("img", {
    staticClass: "bgImg",
    staticStyle: {
      width: "320px",
      height: "500px"
    },
    attrs: {
      src: _vm.qrBg + "?_" + Date.now(),
      crossOrigin: "anonymous",
      alt: "背景图"
    },
    on: {
      load: function load($event) {
        return _vm.getImg();
      }
    }
  }), _c("img", {
    staticClass: "qrImg",
    attrs: {
      src: _vm.imgUrl,
      alt: "二维码",
      crossOrigin: "anonymous"
    }
  })]) : _vm._e(), _vm.testUrl ? _c("div", [_c("img", {
    staticStyle: {
      width: "320px",
      height: "500px"
    },
    attrs: {
      src: _vm.testUrl,
      alt: ""
    }
  }), _c("div", {
    staticClass: "save"
  }, [_vm._v("↑↑↑长按上图保存到相册")])]) : _vm._e()]), _c("van-dialog", {
    attrs: {
      title: "拉新规则",
      "confirm-button-text": "确认"
    },
    model: {
      value: _vm.ruleVisible,
      callback: function callback($$v) {
        _vm.ruleVisible = $$v;
      },
      expression: "ruleVisible"
    }
  }, [_c("div", {
    staticClass: "rule-box"
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v("邀请好友赚积分规则：")]), _c("p", [_vm._v("1.100积分=1元；")]), _c("p", [_vm._v(" 2.您邀请的好友为新用户，好友即可获赠新人专享充值大券包。同时您可获得200积分。多邀多得，没有上限； （特别提示:如您邀请的好友已经关注过或使用过游全球、手机ip定位不在海外，则不属于新用户） ")]), _c("p", [_vm._v("3.每邀请10位用户额外奖励1000积分；")]), _c("p", [_vm._v(" 4.您邀请的新用户完成充值，您即可获得好友充值成功订单金额的2%积分奖励。 如：好友充值金额为100元 即您可得100*2%=2元=200积分（自成功邀请好友之日30天内有效） ")]), _c("p", [_vm._v(" 5.用户不得以不正当、不诚信的方式参与活动，否则游全球有权撤销用户活动参与资格并收回用户已经获得的奖励权益(包括追偿已使用部分)； ")]), _c("p", [_vm._v("6.如有疑问，可咨询游全球客服；")]), _c("p", [_vm._v("7.以上解释权归游全球所有；")])])]), _c("van-dialog", {
    staticStyle: {
      "border-radius": "30px"
    },
    attrs: {
      "show-confirm-button": false
    },
    model: {
      value: !this.$store.state.isLoginUser && !_vm.wxBrowser,
      callback: function callback($$v) {
        _vm.$set(!this.$store.state, "isLoginUser && !wxBrowser", $$v);
      },
      expression: "!this.$store.state.isLoginUser && !wxBrowser"
    }
  }, [_c("div", {
    staticStyle: {
      margin: "0 auto",
      "backlground-color": "#3535e3",
      width: "100%",
      height: "100%"
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: require("../../../../src/assets/WxFollowThePublicAccountQrCode.png"),
      alt: "",
      crossOrigin: "anonymous"
    }
  })])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("img", {
    staticStyle: {
      "margin-top": "20px",
      "padding-left": "65px"
    },
    attrs: {
      width: "80%",
      src: require("@@/userCenter/InviteAPerson.png"),
      alt: "邀请三步骤"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("img", {
    staticStyle: {
      "margin-top": "35px",
      "padding-left": "10px"
    },
    attrs: {
      width: "60%",
      src: require("@@/userCenter/ExtraBonus.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("img", {
    staticStyle: {
      "margin-top": "35px",
      "padding-left": "10px"
    },
    attrs: {
      width: "75%",
      src: require("@@/userCenter/10Coupon.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      margin: "0 2%"
    }
  }, [_c("img", {
    attrs: {
      src: require("@@/userCenter/RewardGuide.png"),
      width: "100%",
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "desc"
  }, [_c("p", [_vm._v("1.点击右上角")]), _c("p", [_vm._v("2.点击分享给好友或分享至朋友圈")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };