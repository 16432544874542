var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "pointWealthList"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "16px",
      height: "20px"
    }
  }, [_vm._m(0), _c("wx-open-subscribe", {
    staticStyle: {
      width: "100%",
      height: "40px",
      background: "red",
      position: "absolute",
      right: "5px",
      top: "15px",
      opacity: "0"
    },
    attrs: {
      template: _vm.subTemplateId,
      id: "subscribe-btn"
    },
    on: {
      success: _vm.success2,
      error: _vm.subError2
    }
  }, [_c("script", {
    attrs: {
      type: "text/wxtag-template"
    }
  }, [_vm._v(' <style> .btn2 { width: 45px; height: 50px; border: none; padding: 15px 0; color: red; } </style> <button class="btn2">立即参与</button> ')])])], 1), _c("van-list", {
    attrs: {
      finished: _vm.finished,
      "finished-text": "没有更多了"
    },
    on: {
      load: _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_c("ul", {
    staticClass: "pointWealthList"
  }, _vm._l(_vm.pointConsumeRecordList, function (item) {
    return _c("record-item", {
      key: item.id,
      attrs: {
        info: item,
        operation: item.operation
      }
    });
  }), 1)])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      width: "100%",
      "text-align": "right",
      margin: "20px 0",
      "font-size": "16px",
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      padding: "0 5px",
      "box-sizing": "border-box"
    }
  }, [_c("span", {
    staticStyle: {
      float: "left",
      color: "#999",
      "margin-left": "10px"
    }
  }, [_vm._v("开启积分到账通知")]), _c("span", {
    staticStyle: {
      color: "#ae8f63",
      float: "right",
      "margin-right": "10px",
      padding: "0 5px",
      border: "1px solid #ae8f63",
      "border-radius": "10px"
    }
  }, [_vm._v("订阅")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };